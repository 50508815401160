<template>
    <div>
        员工课消
    </div>
</template>

<script>
    export default {
        name:'employee'
    }
</script>

<style lang="scss" scoped>

</style>